var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "padding-left": "60px"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "card-heading"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "24px",
      "padding": "12px"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "height": "160",
      "type": "image",
      "width": "160"
    }
  }), _c('div', {
    staticStyle: {
      "flex": "1"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "paragraph"
    }
  }), _c('v-skeleton-loader', {
    attrs: {
      "type": "sentences"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }