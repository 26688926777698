export const downloadUDIDs = (udidList, id) => {
  // Function to convert UDID to XML with a generic device name
  function udidToXML(udid) {
    let genericDeviceName = "iOS Device"; // Generic device name

    return `<dict>
                    <key>devicePlatform</key>
                    <string>ios</string>
                    <key>deviceIdentifier</key>
                    <string>${udid}</string>
                    <key>deviceName</key>
                    <string>${genericDeviceName}</string>
                </dict>`;
  }

  // Convert array of UDIDs to XML
  let deviceListXML = udidList.map((udid) => udidToXML(udid)).join("\n");

  // Create the full XML string
  let outputString = `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd">
<plist version="1.0">
    <dict>
        <key>Device UDIDs</key>
        <array>
${deviceListXML}
        </array>
    </dict>
</plist>`;

  // Create a blob from the XML string
  let blob = new Blob([outputString], { type: "application/xml" });

  // Construct the file name
  let fileName = `testappio_release_${id}_device_list.deviceids`;

  // Create a link and trigger a download
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(link.href);
};
