<template>
  <div>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn
          :disabled="getReleaseDetail.status !== 1"
          color="primary"
          class="text-transform-none"
          :outlined="$vuetify.theme.dark"
          @click="showReleaseCommentForm = true"
          >Post Comment</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="busy || this.currentPageData.length">
      <v-col cols="4" style="position: relative">
        <template v-if="!busy">
          <stack class="comment-cards">
            <v-card
              v-for="comment in currentPageData"
              :key="comment.id"
              :class="
                activeComment?.id === comment.id ? 'comment-card--active' : ''
              "
              class="comment-card"
              outlined
              rounded
              @click="activeComment = comment"
            >
              <div class="comment-card__title">Comment {{ comment.id }}</div>
              <div class="comment-card__message">
                <template v-if="comment.message">
                  <MaxText :text="comment.message" :max="95"></MaxText>
                </template>
                <span v-else class="comment-card__title--empty"
                  >No message</span
                >
              </div>
              <div class="comment-card__bottom">
                <div class="comment-card__created-at">
                  <date-tooltip :date="comment.created_at" />
                </div>
                <div class="comment-card__icons">
                  <flex-row v-if="comment.stats.attachments" gap="4px">
                    <span>{{ comment.stats.attachments }}</span>
                    <v-icon> mdi-attachment</v-icon>
                  </flex-row>
                  <feedback-status
                    v-show="comment.status"
                    :status="comment.status"
                  ></feedback-status>
                </div>
              </div>
            </v-card>
          </stack>
          <div
            v-if="this.isPaginationVisible && !showViewAll"
            class="comment-cards__pagination"
          >
            <v-btn :disabled="!showPrev" icon @click="prevPage">
              <v-icon> mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn :disabled="!showNext" icon @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <v-row
            v-if="
              commentId &&
              getTotalCommentCount > currentPageData.length &&
              showViewAll
            "
            justify="center"
            style="margin-top: 16px"
          >
            <v-col cols="auto">
              <v-btn
                :outlined="$vuetify.theme.dark"
                color="primary"
                @click="handleViewAll"
                >View all</v-btn
              >
            </v-col>
          </v-row>
        </template>
        <stack v-else>
          <v-skeleton-loader
            v-for="index in 4"
            :key="index"
            type="article"
          ></v-skeleton-loader>
        </stack>
      </v-col>
      <v-col cols="8">
        <active-comment
          v-if="activeComment?.id"
          :active-comment="activeComment"
          @onAttachmentDelete="handleAttachmentDelete"
          @onDelete="handleCommentDelete"
          @onStatusChange="handleFeedbackStatusChange"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-card :outlined="$vuetify.theme.dark" flat>
          <no-data
            align-class="text-left"
            image="comments_empty_state"
            :first-text="
              setCommentErrorMessage ?? 'No Comments for This Release... Yet!'
            "
            second-text="<ul> <li><strong>Team</strong>: Team feedback will appear here.</li> <li><strong>Guests</strong>: Shared with external testers? Their feedback shows up here. Remember, you can invite unlimited Guests for feedback in our app.</li> </ul>"
          ></no-data>
        </v-card>
      </v-col>
    </v-row>
    <Modal
      v-model="showReleaseCommentForm"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      width="800"
    >
      <template #card>
        <v-card v-if="showReleaseCommentForm">
          <v-card-title>
            <div>
              <div>Post Comment</div>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="showReleaseCommentForm = false">
              <v-icon v-text="'mdi-close'"></v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-6">
            <feedback-form
              :release-info="getReleaseDetail"
              from-release-page
              @success="getComments"
              @close="showReleaseCommentForm = false"
            ></feedback-form>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Stack from "@/view/components/Common/Stack.vue";
import NoData from "@/view/components/Common/NoData.vue";
import apiRoutes from "@/consts/routes/apiRoutes";
import ActiveComment from "@/view/components/Comment/ActiveComment";
import FeedbackForm from "@/view/components/Releases/FeedbackForm";
import FeedbackStatus from "@/view/components/Feedback/FeedbackStatus";

export default {
  components: {
    FeedbackStatus,
    Stack,
    NoData,
    ActiveComment,
    FeedbackForm,
  },
  props: {
    release: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      deleteFeedbackDialog: false,
      activeCommentStatus: null,
      showReleaseCommentForm: false,
      busy: false,
      attachmentsBusy: true,
      showViewAll: true,
      activeComment: null,
      attachments: null,
      activeMedia: null,
      setCommentErrorMessage: null,
      commentPagination: {
        currentPage: 0,
        itemsPerPage: 5,
        totalItems: 0,
        totalPages: 0,
      },
      currentPageData: [],
      allLoadedData: [],
    };
  },
  computed: {
    ...mapGetters({
      getAllReleaseComments: "getAllReleaseComments",
      getReleaseDetail: "getReleaseDetail",
      getTotalReleaseComments: "getTotalReleaseComments",
      getTotalCommentCount: "getTotalCommentCount",
    }),
    showPrev() {
      return (
        this.isPaginationVisible && this.commentPagination.currentPage + 1 > 1
      );
    },
    showNext() {
      return (
        this.isPaginationVisible &&
        this.commentPagination.currentPage + 1 <
          this.commentPagination.totalPages
      );
    },
    isPaginationVisible() {
      return (
        this.commentPagination.totalItems > this.commentPagination.itemsPerPage
      );
    },
    lastFeedbackItem() {
      return this.currentPageData[this.currentPageData.length - 1];
    },
    commentId() {
      return this.$route.params.comment_id;
    },
  },
  watch: {
    getTotalCommentCount: function (value) {
      this.commentPagination.totalItems = value;
      this.commentPagination.totalPages = Math.ceil(
        this.commentPagination.totalItems / this.commentPagination.itemsPerPage
      );
    },
    getAllReleaseComments: function (value) {
      if (value.length) {
        this.currentPageData = value;
      }
    },
    getTotalReleaseComments(value) {
      if (value.length) {
        this.allLoadedData = value;
      }
    },
    // "$route.params.release_id" () {
    //   this.setTotalCommentCount(0)
    //   this.setAllReleaseComment([])
    // },
    "commentPagination.currentPage": function (val, oldVal) {
      if (val == null || val < 0 || val === oldVal) {
        return;
      }

      const { itemsPerPage } = this.commentPagination;
      let offset = val * itemsPerPage;

      if (val > oldVal) {
        if (this.allLoadedData.length > (oldVal + 1) * itemsPerPage) {
          this.currentPageData = this.allLoadedData.slice(
            offset,
            offset + itemsPerPage
          );
        } else {
          this.getComments();
        }
      } else {
        this.currentPageData = this.allLoadedData.slice(
          offset,
          offset + itemsPerPage
        );
      }
    },
    "$route.params.comment_id": function (value) {
      if (value) {
        this.getCommentById(value);
      }
    },
  },
  created() {
    if (this.commentId) {
      this.getCommentById(this.commentId);
    } else {
      this.setTotalCommentCount(0);
      this.setAllReleaseComment([]);
      this.getComments().then(() => {
        // if (data?.data?.length) {
        //   const [activeComment] = data?.data;
        //   this.activeComment = activeComment;
        //   this.currentPageData = data?.data;
        //   this.allLoadedData = data?.data
        // }
      });
    }
  },
  methods: {
    ...mapMutations({
      setTotalCommentCount: "setTotalCommentCount",
      setAllReleaseComment: "setAllReleaseComment",
      setTotalReleaseComment: "setTotalReleaseComment",
    }),
    getComments() {
      if (this.busy) return;
      this.busy = true;
      const params = {
        release_id: this.$route.params.release_id || this.release?.id || "",
        limit: this.commentPagination.itemsPerPage,
        page: this.commentPagination.currentPage + 1,
      };
      return this.$coreApi
        .get(
          `${this.apiRoutes.core.app.getCommentsByAppId(
            this.$route.params.app_id || ""
          )}?${this.getQuery(params)}`
        )
        .then(({ data }) => {
          this.showViewAll = false;
          this.setTotalCommentCount(data?.meta?.count);
          this.activeComment = data?.data[0];
          if (this.commentPagination.currentPage === 0) {
            this.setTotalReleaseComment(data?.data);
            this.setAllReleaseComment(data?.data);
          } else {
            this.setTotalReleaseComment(data?.data);
          }
          return data;
        })
        .catch((error) => {
          this.notifyErrorMessage(error.message);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    getCommentById(commentId) {
      this.setCommentErrorMessage = null;
      this.busy = true;

      return this.$coreApi
        .get(
          apiRoutes.core.app.getCommentById(
            this.$route.params.app_id,
            commentId
          )
        )
        .then(({ data }) => {
          this.currentPageData = [data];
          this.activeComment = data;
          if (this.release) {
            this.setTotalCommentCount(this.release?.stats?.comments);
          }
        })
        .catch((error) => {
          if ([404, 406].includes(error?.status)) {
            this.setCommentErrorMessage = error?.message;
          } else {
            this.notifyErrorMessage(error?.message);
          }
        })
        .finally(() => {
          this.busy = false;
        });
    },
    nextPage() {
      if (
        this.commentPagination.currentPage < this.commentPagination.totalPages
      ) {
        this.commentPagination.currentPage += 1;
      }
    },
    prevPage() {
      if (this.commentPagination.currentPage + 1 > 1) {
        this.commentPagination.currentPage -= 1;
      }
    },
    handleViewAll() {
      this.getComments();
    },
    handleFeedbackStatusChange(status) {
      const mapFeedback = (comment) => {
        if (comment.id === this.activeComment?.id) {
          return {
            ...comment,
            status,
          };
        }
        return comment;
      };
      this.allLoadedData = this.allLoadedData.map(mapFeedback);
      this.currentPageData = this.currentPageData.map(mapFeedback);
      this.activeComment = { ...this.activeComment, status };
    },
    async handleCommentDelete(commentId) {
      this.allLoadedData = this.allLoadedData.filter(
        (comment) => comment.id !== commentId
      );
      this.currentPageData = this.currentPageData.filter(
        (comment) => comment.id !== commentId
      );
      this.commentPagination.totalItems =
        this.commentPagination.totalItems -= 1;
      this.commentPagination.totalPages = this.allLoadedData.length;
      if (this.currentPageData.length) {
        this.activeComment = this.currentPageData[0];
      } else {
        this.commentPagination.currentPage -= 1;
      }
      this.activeComment = this.commentPagination;
    },
    handleAttachmentDelete(commentId) {
      const mapData = (comment) => {
        if (comment.id === commentId) {
          return {
            ...comment,
            stats: {
              ...comment.stats,
              attachments: comment.stats.attachments - 1,
            },
          };
        }
        return comment;
      };

      this.allLoadedData = this.allLoadedData.map(mapData);
      this.currentPageData = this.currentPageData.map(mapData);
    },
  },
};
</script>

<style lang="scss">
.v-toolbar {
  box-shadow: none;
}

.comment-cards__pagination {
  display: flex;
  gap: 48px;
  justify-content: center;
  left: 50%;
  margin-top: 16px;
  position: absolute;
  transform: translateX(-50%);
}

.comment-card {
  $card: &;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 112px;
  padding: 12px 16px 8px 12px;
  position: relative;
  transition: border-color 0.2s ease-in-out;

  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
  }

  &__message {
    font-size: 14px;
    line-height: 1.7;

    &--empty {
      color: $grey;
    }
  }

  &__bottom {
    align-items: center;
    color: $grey;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  &__icons {
    align-items: center;
    display: flex;
    gap: 16px;
  }

  &--active {
    background: linear-gradient(
        0deg,
        rgba(64, 98, 215, 0.08) 0%,
        rgba(64, 98, 215, 0.08) 100%
      ),
      #fff !important;
    border-color: $blue !important;

    &:before {
      background: $blue;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 4px;
    }

    #{$card}__title,
    #{$card}__message {
      color: $blue;
    }
  }

  &__created-at {
    font-size: 12px;
  }
}

.theme--dark.comment-card--active {
  background: rgba(70, 78, 95, 0.2) !important;
  border-color: $blue !important;
}

.theme--dark.comment-card--active .comment-card__title,
.comment-card--active .comment-card__message {
  color: $primary-text-color;
}

.theme--dark .comment-card__bottom {
  color: $primary-text-color;
}
</style>
