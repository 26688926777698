var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "attachment",
    on: {
      "mouseenter": _vm.handleMouseEnter,
      "mouseleave": _vm.handleMouseLeave
    }
  }, [_c('div', {
    staticClass: "attachment__header"
  }, [!_vm.fromCommentPage ? _c('div', {
    staticClass: "attachment__imported"
  }, [_vm.attachment.file.imported ? [_c('gallery-folder-logo'), _c('span', [_vm._v("Gallery Upload")])] : [_c('logo'), _c('span', [_vm._v("SDK Capture")])]], 2) : _vm._e(), _c('div', {
    staticClass: "attachment__date"
  }, [_c('date-tooltip', {
    attrs: {
      "date": _vm.attachment.created_at
    }
  })], 1), _vm.attachment.session_id !== _vm.$route.params.session_id ? _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-chip', _vm._g({
          attrs: {
            "color": "#E3F2FD",
            "text-color": "primary"
          },
          on: {
            "click": function click($event) {
              var _vm$attachment;
              return _vm.openSession((_vm$attachment = _vm.attachment) === null || _vm$attachment === void 0 ? void 0 : _vm$attachment.session_id);
            }
          }
        }, on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-circle-medium")]), _vm._v(" " + _vm._s(_vm.attachment.session_id))], 1)];
      }
    }], null, false, 1965986478)
  }, [_c('span', [_vm._v(" From Session ")])])], 1) : _vm._e(), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showDelete,
      expression: "showDelete"
    }],
    staticClass: "attachment__delete",
    attrs: {
      "icon": "",
      "disabled": [4, 5, 3].includes(_vm.attachment.status),
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-trash-can-outline")])], 1)], 1), _c('div', {
    staticClass: "attachment__body"
  }, [_c('v-card', {
    staticClass: "attachment__image",
    attrs: {
      "flat": ""
    }
  }, [[4, 5].includes(_vm.attachment.status) ? _c('div', {
    staticClass: "backgroundOverlay",
    attrs: {
      "opacity": "1",
      "color": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-progress-circular', {
    attrs: {
      "color": "info",
      "size": "45",
      "indeterminate": ""
    }
  }), _c('div', {
    staticClass: "bodyFont mt-2"
  }, [_vm._v(" " + _vm._s(_vm.$options.STATUS[_vm.attachment.status]) + " ")])], 1)]) : _vm._e(), !_vm.erroneousImages.includes(_vm.attachment.file.thumbnail) ? [_c('img', {
    attrs: {
      "src": _vm.attachment.file.thumbnail || _vm.attachment.file.url,
      "alt": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onMediaClick', _vm.attachment.file);
      },
      "error": function error($event) {
        return _vm.handleImageLoadingError(_vm.attachment.file.thumbnail);
      }
    }
  }), _vm.attachment.file.mime.includes('video') ? _c('div', {
    staticClass: "attachment__video-overlay"
  }, [_c('v-icon', {
    staticStyle: {
      "font-size": "48px"
    },
    attrs: {
      "color": "white"
    }
  }, [_vm._v("mdi-play-circle-outline ")])], 1) : _vm._e()] : !_vm.attachment.file.thumbnail ? _c('div', {
    staticStyle: {
      "background": "rgba(57, 107, 219, 0.06)",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "height": "100%"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "error",
      "x-large": ""
    }
  }, [_vm._v("mdi-delete-off")]), _c('div', {
    staticClass: "mt-1"
  }, [_vm._v("Image deleted system.")])], 1) : _c('div', {
    staticStyle: {
      "background": "rgba(57, 107, 219, 0.06)",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "height": "100%"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "error",
      "x-large": ""
    }
  }, [_vm._v("mdi-image-broken-variant")])], 1)], 2), _vm.attachment.note ? _c('div', {
    staticClass: "attachment__note",
    domProps: {
      "innerHTML": _vm._s(_vm.note)
    }
  }) : _c('span', {
    staticClass: "attachment__note attachment__note--empty"
  }, [_vm._v("No message")])], 1), _c('v-dialog', {
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "font-size-h3"
  }, [_vm._v("Delete attachment")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "bodyFont p-2"
  }, [_vm._v(" Are you sure you want to delete this attachment? ")]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Cancel")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "loading": _vm.deleteAttachmentLoading,
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        return _vm.deleteAttachment();
      }
    }
  }, [_vm._v("Delete ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }