<template>
  <v-card>
    <v-card-title>
      <v-row align="center">
        <v-col>
          <div class="subListFont">{{ title }}</div>
          <div class="bodyFont" v-html="subTitle"></div>
        </v-col>
        <v-col class="text-right" cols="1">
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col class="mt-6 text-center" cols="12">
          <div class="primary--text">Try me 👇</div>
        </v-col>
        <v-col class="mb-4" cols="12">
          <div
            style="
              position: relative;
              padding-bottom: calc(67.51054852320675% + 41px);
              height: 0;
            "
          >
            <iframe
              :src="iFrameLink"
              :title="iFrameTitle"
              allowfullscreen
              frameborder="0"
              loading="lazy"
              mozallowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color-scheme: light;
              "
              webkitallowfullscreen
            ></iframe>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-checkbox
        v-if="showCheckbox"
        v-model="openHelpModal"
        label="Don't show this again"
      ></v-checkbox>
      <v-spacer></v-spacer>
      <v-btn
        class="my-5 text-transform-none"
        color="primary"
        large
        :outlined="$vuetify.theme.dark"
        @click="openLink"
        >Documentation
      </v-btn>
      <v-spacer v-if="!showCheckbox"></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: "",
    },
    documentLink: {
      type: String,
      default: "",
    },
    iFrameLink: {
      type: String,
      default: "",
    },
    iFrameTitle: {
      type: String,
      default: "",
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    eventName: {
      type: String,
      default: "",
    },
    eventType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      openHelpModal: false,
    };
  },
  created() {
    this.logEvent("Opened demo popup", {
      type: this.eventName,
      from: this.eventType || this.$route.path,
    });
  },
  watch: {
    openHelpModal(val) {
      localStorage.setItem(
        "popup",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("popup")),
          help_udid: val.toString(),
        })
      );
    },
  },
  methods: {
    openLink() {
      window.open(this.documentLink, "_blank");
    },
  },
};
</script>
