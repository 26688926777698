var render = function render(){
  var _vm$activeComment2, _vm$setCommentErrorMe;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "disabled": _vm.getReleaseDetail.status !== 1,
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        _vm.showReleaseCommentForm = true;
      }
    }
  }, [_vm._v("Post Comment")])], 1)], 1), _vm.busy || this.currentPageData.length ? _c('v-row', [_c('v-col', {
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "cols": "4"
    }
  }, [!_vm.busy ? [_c('stack', {
    staticClass: "comment-cards"
  }, _vm._l(_vm.currentPageData, function (comment) {
    var _vm$activeComment;
    return _c('v-card', {
      key: comment.id,
      staticClass: "comment-card",
      class: ((_vm$activeComment = _vm.activeComment) === null || _vm$activeComment === void 0 ? void 0 : _vm$activeComment.id) === comment.id ? 'comment-card--active' : '',
      attrs: {
        "outlined": "",
        "rounded": ""
      },
      on: {
        "click": function click($event) {
          _vm.activeComment = comment;
        }
      }
    }, [_c('div', {
      staticClass: "comment-card__title"
    }, [_vm._v("Comment " + _vm._s(comment.id))]), _c('div', {
      staticClass: "comment-card__message"
    }, [comment.message ? [_c('MaxText', {
      attrs: {
        "text": comment.message,
        "max": 95
      }
    })] : _c('span', {
      staticClass: "comment-card__title--empty"
    }, [_vm._v("No message")])], 2), _c('div', {
      staticClass: "comment-card__bottom"
    }, [_c('div', {
      staticClass: "comment-card__created-at"
    }, [_c('date-tooltip', {
      attrs: {
        "date": comment.created_at
      }
    })], 1), _c('div', {
      staticClass: "comment-card__icons"
    }, [comment.stats.attachments ? _c('flex-row', {
      attrs: {
        "gap": "4px"
      }
    }, [_c('span', [_vm._v(_vm._s(comment.stats.attachments))]), _c('v-icon', [_vm._v(" mdi-attachment")])], 1) : _vm._e(), _c('feedback-status', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: comment.status,
        expression: "comment.status"
      }],
      attrs: {
        "status": comment.status
      }
    })], 1)])]);
  }), 1), this.isPaginationVisible && !_vm.showViewAll ? _c('div', {
    staticClass: "comment-cards__pagination"
  }, [_c('v-btn', {
    attrs: {
      "disabled": !_vm.showPrev,
      "icon": ""
    },
    on: {
      "click": _vm.prevPage
    }
  }, [_c('v-icon', [_vm._v(" mdi-chevron-left")])], 1), _c('v-btn', {
    attrs: {
      "disabled": !_vm.showNext,
      "icon": ""
    },
    on: {
      "click": _vm.nextPage
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1) : _vm._e(), _vm.commentId && _vm.getTotalCommentCount > _vm.currentPageData.length && _vm.showViewAll ? _c('v-row', {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": _vm.handleViewAll
    }
  }, [_vm._v("View all")])], 1)], 1) : _vm._e()] : _c('stack', _vm._l(4, function (index) {
    return _c('v-skeleton-loader', {
      key: index,
      attrs: {
        "type": "article"
      }
    });
  }), 1)], 2), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [(_vm$activeComment2 = _vm.activeComment) !== null && _vm$activeComment2 !== void 0 && _vm$activeComment2.id ? _c('active-comment', {
    attrs: {
      "active-comment": _vm.activeComment
    },
    on: {
      "onAttachmentDelete": _vm.handleAttachmentDelete,
      "onDelete": _vm.handleCommentDelete,
      "onStatusChange": _vm.handleFeedbackStatusChange
    }
  }) : _vm._e()], 1)], 1) : _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "flat": ""
    }
  }, [_c('no-data', {
    attrs: {
      "align-class": "text-left",
      "image": "comments_empty_state",
      "first-text": (_vm$setCommentErrorMe = _vm.setCommentErrorMessage) !== null && _vm$setCommentErrorMe !== void 0 ? _vm$setCommentErrorMe : 'No Comments for This Release... Yet!',
      "second-text": "<ul> <li><strong>Team</strong>: Team feedback will appear here.</li> <li><strong>Guests</strong>: Shared with external testers? Their feedback shows up here. Remember, you can invite unlimited Guests for feedback in our app.</li> </ul>"
    }
  })], 1)], 1)], 1), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": "800"
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.showReleaseCommentForm ? _c('v-card', [_c('v-card-title', [_c('div', [_c('div', [_vm._v("Post Comment")])]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.showReleaseCommentForm = false;
            }
          }
        }, [_c('v-icon', {
          domProps: {
            "textContent": _vm._s('mdi-close')
          }
        })], 1)], 1), _c('v-card-text', {
          staticClass: "pa-6"
        }, [_c('feedback-form', {
          attrs: {
            "release-info": _vm.getReleaseDetail,
            "from-release-page": ""
          },
          on: {
            "success": _vm.getComments,
            "close": function close($event) {
              _vm.showReleaseCommentForm = false;
            }
          }
        })], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showReleaseCommentForm,
      callback: function callback($$v) {
        _vm.showReleaseCommentForm = $$v;
      },
      expression: "showReleaseCommentForm"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }