var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', [_c('v-card', {
    staticClass: "transparent",
    attrs: {
      "tile": "",
      "flat": "",
      "disabled": _vm.btnLoader
    }
  }, [_c('v-form', {
    ref: "ios",
    staticClass: "form mt-8",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return function () {
          return _vm.submitRelease();
        }.apply(null, arguments);
      }
    }
  }, [[_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:600',
      expression: "'max:600'"
    }],
    attrs: {
      "counter": 600,
      "label": "Notes",
      "placeholder": "What to expect or test in this release?",
      "auto-grow": "",
      "filled": ""
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  }, 'v-textarea', _vm.veeValidate('Description', 'Description'), false))], _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    ref: "submit_btn",
    staticClass: "text-transform-none mt-13 px-10 py-7 mx-auto",
    attrs: {
      "loading": _vm.btnLoader,
      "disabled": _vm.btnLoader || !_vm.form.note || _vm.errors.any(),
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "x-large": "",
      "type": "submit"
    }
  }, [_vm._v("Update")])], 1)], 2)], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-8 m-0 semi-bold text-center titleFont"
  }, [_vm._v(" Release Notes ")])]);

}]

export { render, staticRenderFns }