<template>
  <div
    class="attachment"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="attachment__header">
      <div v-if="!fromCommentPage" class="attachment__imported">
        <template v-if="attachment.file.imported">
          <gallery-folder-logo />
          <span>Gallery Upload</span>
        </template>
        <template v-else>
          <logo />
          <span>SDK Capture</span>
        </template>
      </div>
      <div class="attachment__date">
        <date-tooltip :date="attachment.created_at" />
      </div>
      <div v-if="attachment.session_id !== $route.params.session_id">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              @click="openSession(attachment?.session_id)"
              color="#E3F2FD"
              text-color="primary"
            >
              <v-icon left>mdi-circle-medium</v-icon>
              {{ attachment.session_id }}</v-chip
            >
          </template>
          <span> From Session </span>
        </v-tooltip>
      </div>
      <v-btn
        v-show="showDelete"
        class="attachment__delete"
        icon
        :disabled="[4, 5, 3].includes(attachment.status)"
        small
        @click="dialog = true"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </div>
    <div class="attachment__body">
      <v-card flat class="attachment__image">
        <div
          opacity="1"
          class="backgroundOverlay"
          color
          v-if="[4, 5].includes(attachment.status)"
        >
          <div class="text-center">
            <v-progress-circular
              color="info"
              size="45"
              indeterminate
            ></v-progress-circular>
            <div class="bodyFont mt-2">
              {{ $options.STATUS[attachment.status] }}
            </div>
          </div>
        </div>
        <template v-if="!erroneousImages.includes(attachment.file.thumbnail)">
          <img
            :src="attachment.file.thumbnail || attachment.file.url"
            alt=""
            @click="$emit('onMediaClick', attachment.file)"
            @error="handleImageLoadingError(attachment.file.thumbnail)"
          />
          <div
            v-if="attachment.file.mime.includes('video')"
            class="attachment__video-overlay"
          >
            <v-icon color="white" style="font-size: 48px"
              >mdi-play-circle-outline
            </v-icon>
          </div>
        </template>
        <div
          v-else-if="!attachment.file.thumbnail"
          style="
            background: rgba(57, 107, 219, 0.06);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          "
        >
          <v-icon color="error" x-large>mdi-delete-off</v-icon>
          <div class="mt-1">Image deleted system.</div>
        </div>
        <div
          v-else
          style="
            background: rgba(57, 107, 219, 0.06);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          "
        >
          <v-icon color="error" x-large>mdi-image-broken-variant</v-icon>
        </div>
      </v-card>
      <div v-if="attachment.note" class="attachment__note" v-html="note" />
      <span v-else class="attachment__note attachment__note--empty"
        >No message</span
      >
    </div>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="font-size-h3">Delete attachment</v-card-title>

        <v-divider />

        <v-card-text class="bodyFont p-2">
          Are you sure you want to delete this attachment?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            class="text-transform-none"
            :outlined="$vuetify.theme.dark"
            color="primary"
            text
            @click="dialog = false"
          >
            Cancel</v-btn
          >
          <v-btn
            :loading="deleteAttachmentLoading"
            color="primary"
            class="text-transform-none"
            :outlined="$vuetify.theme.dark"
            @click="deleteAttachment()"
            >Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    fromCommentPage: {
      type: Boolean,
      default: false,
    },
    commentId: {
      type: String,
      default: "",
    },
  },
  STATUS: {
    1: "Active",
    4: "Uploading",
    5: "Processing",
  },
  data() {
    return {
      erroneousImages: [],
      showDelete: false,
      dialog: false,
      deleteAttachmentLoading: false,
    };
  },
  computed: {
    isVideo() {
      return this.attachment.file.mime.includes("video");
    },
    note() {
      return this.attachment.note.split("\n").join("<br>");
    },
  },
  methods: {
    handleImageLoadingError(index) {
      this.erroneousImages.push(index);
    },
    openSession(id) {
      this.$router.push({
        name: "sessions-detail",
        params: { app_id: this.$route.params.app_id, session_id: id },
      });
    },
    handleMouseEnter() {
      this.showDelete = true;
    },
    handleMouseLeave() {
      this.showDelete = false;
    },
    async deleteAttachment() {
      let endpoint = this.fromCommentPage
        ? this.apiRoutes.core.app.commentAttachmentById(
            this.$route.params.app_id,
            this.commentId,
            this.attachment.id
          )
        : this.apiRoutes.core.session.attachmentById(this.attachment.id);
      try {
        this.deleteAttachmentLoading = true;
        const { data } = await this.$coreApi.delete(endpoint);

        this.EventBus.$emit("notification", {
          color: "success",
          text: data.message,
        });

        this.dialog = false;
        this.$emit("onDelete", this.attachment.id);
      } catch (error) {
        this.notifyErrorMessage(error.message);
      } finally {
        this.deleteAttachmentLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment {
  border: 1px solid rgba(64, 98, 215, 0.06) !important;
  border-radius: 6px;
  overflow: hidden;

  &__header {
    align-items: center;
    background: rgba(64, 98, 215, 0.06);
    display: flex;
    font-weight: 500;
    gap: 24px;
    padding: 14px 24px;
  }

  &__body {
    display: flex;
    gap: 24px;
    padding: 12px;
  }

  &__note {
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 1.5;
    padding: 8px 0;

    &--empty {
      color: $grey;
    }
  }

  &__imported {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  &__delete {
    position: absolute;
    right: 24px;
  }

  &__image {
    //background: lightgrey;
    @include size(160px);
    border-radius: 6px;
    cursor: pointer;
    flex-shrink: 0;
    min-width: 160px;
    overflow: hidden;
    position: relative;

    & > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__video-overlay {
    align-items: center;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.theme--dark .attachment {
  border: 1px solid #464e5f !important;
  border-radius: 6px;
  overflow: hidden;

  &__header {
    align-items: center;
    background: transparent;
    border: 1px solid #464e5f !important;
    display: flex;
    font-weight: 500;
    gap: 24px;
    padding: 14px 24px;
  }
}

.theme--dark .attachment__note {
  color: #adbac7 !important;
}

.backgroundOverlay {
  background-color: #ebebeb;
  @include size(160px);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
