<template>
  <div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-8 m-0 semi-bold  text-center titleFont">
       Release Notes
      </div>
    </div>
    <div>
      <v-card class="transparent" tile flat :disabled="btnLoader">
        <v-form
          ref="ios"
          class="form mt-8"
          @submit.prevent="() => submitRelease()"
        >
          <!--  -->
          <template>
            <v-textarea
              v-model="form.note"
              :counter="600"
              v-validate="'max:600'"
              v-bind="veeValidate('Description', 'Description')"
              label="Notes"
              placeholder="What to expect or test in this release?"
              auto-grow
              filled
            ></v-textarea>
          </template>
          <div class="text-center">
            <v-btn
              :loading="btnLoader"
              :disabled="btnLoader || !form.note || errors.any()"
              color="primary"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none mt-13 px-10 py-7 mx-auto"
              ref="submit_btn"
              x-large
              type="submit"
              >Update</v-btn
            >
          </div>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import { get } from "@/core/services/helper.service";
import { UPDATE_RELEASE } from "@/store/releases/releases.module.js";
import veeValidate from "@/mixins/veeValidate";

export default {
  name: "create-release",
  props: ["app_id", "release", "onClose"],
  mixins: [veeValidate],
  components: {},
  data() {
    return {
      show: null,
      btnLoader: false,
      allValid: false,
      form: {
        note: "",
      },
      get,
    };
  },
  watch: {
    release: function(value) {
      this.form.note = get(value, "note", "");
    },
  },
  created() {
    this.form.note = this.release.note;
  },
  methods: {
    resetForm() {
      this.form = {
        note: "",
      };
    },
    async submitRelease() {
      if (this.errors.any() || this.btnLoader) {
        return false;
      }
      this.btnLoader = true;

      try {
        const response = await this.$store.dispatch(UPDATE_RELEASE, {
          app_id: this.app_id,
          release_id: this.release.id,
          note: this.form.note,
        });
        this.$emit('success', this.form.note)
        this.notifyUserMessage({
          message: response.message || `Release updated successfully`,
        });
      } catch (err) {
        this.notifyErrorMessage(err.message || `Update release failed`);
      }
      this.btnLoader = false;
    },
  },
};
</script>
