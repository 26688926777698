<template>
  <v-card v-if="activeComment" class="feedback-chat" outlined rounded>
    <div class="feedback-chat__header">
      <div class="feedback-chat__title">
        <span>Comment #{{ activeComment.id }}</span>
      </div>
      <div class="feedback-chat__header-chips">
        <v-chip>
          <v-icon color="black" left>mdi-account-circle-outline</v-icon>
          <span class="font-medium">{{ activeComment.user.name }}</span>
        </v-chip>
        <v-chip
          v-if="activeComment?.user?.external && activeComment?.user?.email"
        >
          <v-icon color="black" left>mdi-mail</v-icon>
          <span class="font-medium">{{ activeComment.user.email }}</span>
        </v-chip>
        <v-chip
          v-if="activeComment.status === 1"
          color="#fff5dd"
          text-color="orange"
        >
          <v-icon color="orange" left>mdi-clock-outline</v-icon>
          <span class="font-medium">Not resolved</span>
        </v-chip>
        <v-chip
          v-if="activeComment.status === 2"
          color="#e0ffe1"
          text-color="green"
        >
          <v-icon color="green" left>mdi-check-circle-outline</v-icon>
          <span class="font-medium">Resolved</span>
        </v-chip>
      </div>
      <v-spacer />
      <div class="feedback-chat__controls">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list style="padding: 0">
            <v-list-item
              ripple
              style="padding: 0 16px 0 12px"
              @click="copyLink"
            >
              <v-icon small>mdi-content-copy</v-icon>
              <v-list-item-title style="font-size: 12px"
                >Copy link
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="canManageRelease"
              ripple
              style="padding: 0 16px 0 12px"
              @click="changeFeedbackStatus(activeComment.status)"
            >
              <v-icon small>mdi-check</v-icon>
              <v-list-item-title style="font-size: 12px">
                Mark as
                {{
                  this.activeComment.status === 1 ? "Resolved" : "Not Resolved"
                }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              ripple
              style="padding: 0 16px 0 12px"
              @click="deleteFeedbackDialog = true"
            >
              <v-icon small>mdi-delete</v-icon>
              <v-list-item-title style="font-size: 12px">
                Delete
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="feedback-chat__body">
      <div class="feedback-chat__messages">
        <div class="feedback-chat__message">
          <template v-if="!attachmentsBusy">
            <div class="feedback-chat__prepend">
              <div class="feedback-chat__prepend-img">
                <v-icon color="primary" style="font-size: 20px"
                  >mdi-message-reply-text-outline
                </v-icon>
              </div>
              <div class="feedback-chat__prepend-stripe"></div>
            </div>
            <div class="feedback-chat__attachment">
              <div
                v-if="activeComment.message"
                class="feedback-chat__attachment-message text-pre-wrap"
                v-html="message"
              />
              <div
                v-else
                class="feedback-chat__attachment-message feedback-chat__attachment-message--empty"
              >
                No message
              </div>

              <div class="feedback-chat__attachment-date darkGrey--text">
                <vue-hoverable-date
                  v-if="activeComment.created_at"
                  :date="activeComment.created_at"
                ></vue-hoverable-date>
              </div>
              <div
                v-if="attachments.length > 0"
                class="feedback-chat__attachment-list"
              >
                <feedback-attachment
                  v-for="(attachment, index) in attachments"
                  :key="index"
                  from-comment-page
                  :attachment="attachment"
                  :comment-id="activeComment.id"
                  @onDelete="handleDeleteAttachment"
                  @onMediaClick="handleMediaClick"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="feedback-chat__attachment-list">
              <feedback-attachment-skeleton
                v-for="index in activeComment?.stats?.attachments"
                :key="index"
              />
            </div>
          </template>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="dialog"
      content-class="attachment-media-dialog"
      height="100%"
      scrollable
      width="auto"
    >
      <img
        v-if="activeMedia && activeMedia.mime.includes('image')"
        :src="activeMedia.url"
        :style="{
          aspectRatio: activeMedia.width / activeMedia.height,
        }"
        height="100%"
      />
      <video
        v-if="activeMedia && activeMedia.mime.includes('video')"
        :src="activeMedia.url"
        :style="{
          aspectRatio: activeMedia.width / activeMedia.height,
        }"
        controls
        height="100%"
      ></video>
    </v-dialog>

    <v-dialog v-model="deleteFeedbackDialog" width="500">
      <v-card>
        <v-card-title class="font-size-h3">Delete Comment</v-card-title>

        <v-divider />

        <v-card-text class="bodyFont p-2">
          Are you sure you want to delete this comment?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            :outlined="$vuetify.theme.dark"
            color="primary"
            text
            @click="deleteFeedbackDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="deleteFeedbackLoading"
            color="primary"
            :outlined="$vuetify.theme.dark"
            @click="handleDelete"
            >Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
  <stack v-else>
    <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
    <v-skeleton-loader
      v-for="index in 2"
      :key="index"
      type="article"
    ></v-skeleton-loader>
  </stack>
</template>

<script>
import FeedbackAttachment from "@/view/components/Sessions/SessionDetailPage/SessionFeedback/FeedbackAttachment/index.vue";
import FeedbackAttachmentSkeleton from "@/view/components/Sessions/SessionDetailPage/SessionFeedback/FeedbackAttachment/FeedbackAttachmentSkeleton.vue";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: { FeedbackAttachmentSkeleton, FeedbackAttachment },
  props: {
    activeComment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      deleteFeedbackDialog: false,
      attachmentsBusy: false,
      attachments: [],
      dialog: false,
      activeMedia: null,
      deleteFeedbackLoading: false,
    };
  },
  computed: {
    ...mapState({
      showSessionDetails: (state) => state.sessions.showSessionDetails,
    }),
    ...mapGetters({
      getCommentAttachmentSocketData: "getCommentAttachmentSocketData",
    }),
    message() {
      return this.activeComment.message.split("\n").join("<br>");
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.activeComment?.stats?.attachments > 0 && this.activeComment.id) {
        this.getFeedbackAttachments(this.activeComment.id);
      }
    });
  },
  methods: {
    ...mapMutations({
      toggleSessionDetails: "toggleSessionDetails",
    }),
    copyLink() {
      this.copyToClipboard(
        `${window.origin}${this.pageRoutes.app.commentDetails.url(
          this.$route.params.app_id,
          this.$route.params.release_id,
          this.activeComment.id
        )}`
      );
    },
    changeFeedbackStatus(currentStatus) {
      const status = currentStatus === 1 ? 2 : 1;
      this.$coreApi
        .put(
          `/app/${this.$route.params.app_id}/comment/${this.activeComment.id}/status`,
          {
            status,
          }
        )
        .then(() => {
          this.$emit("onStatusChange", status);
        })
        .catch((error) => {
          this.notifyErrorMessage(
            error.message ?? "Failed to mark the feedback"
          );
        });
    },
    getFeedbackAttachments(commentId) {
      if (!commentId) return;
      if (this.attachmentsBusy) return;
      this.attachmentsBusy = true;

      return this.$coreApi
        .get(
          this.apiRoutes.core.app.getCommentAttachment(
            this.$route.params.app_id,
            commentId
          )
        )
        .then(({ data }) => {
          this.attachments = data.results.data;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        })
        .finally(() => {
          this.attachmentsBusy = false;
        });
    },
    handleMediaClick(media) {
      this.activeMedia = media;
    },
    async handleDelete() {
      try {
        this.deleteFeedbackLoading = true;
        const { data } = await this.$coreApi.delete(
          this.apiRoutes.core.app.getCommentById(
            this.$route.params.app_id,
            this.activeComment.id
          )
        );

        this.EventBus.$emit("notification", {
          color: "success",
          text: data.message,
        });
        this.deleteFeedbackDialog = false;
        this.$emit("onDelete", this.activeComment.id);
      } catch (error) {
        this.notifyErrorMessage(error.message);
      } finally {
        this.deleteFeedbackLoading = false;
      }
    },
    async handleDeleteAttachment(attachmentId) {
      this.attachments = this.attachments.filter(
        (attachment) => attachment.id !== attachmentId
      );
      this.$emit("onAttachmentDelete", this.activeComment.id);
    },
  },
  watch: {
    getCommentAttachmentSocketData(val) {
      if (
        val.destination === "release_comment" &&
        val.destination_id === this.activeComment.id
      ) {
        this.attachments = this.attachments.map((attachment) => {
          if (attachment.id === val.id) {
            attachment.status = val.status;
            attachment.file.url = val.url;
            attachment.file.thumbnail = val.thumbnail;
          }
          return attachment;
        });
      }
    },
    "activeComment.id"(value) {
      if (this.activeComment?.stats?.attachments > 0) {
        this.getFeedbackAttachments(value);
      } else {
        this.attachments = [];
        this.attachmentsBusy = false;
      }
    },
    activeMedia: function () {
      if (this.activeMedia) {
        this.dialog = true;
      }
    },
    dialog: function () {
      if (!this.dialog) {
        this.activeMedia = null;
      }
    },
  },
};
</script>

<style lang="scss">
.feedback-chat {
  border-radius: 10px;
  display: flex;
  $padding: 16px;
  flex-direction: column;
  max-height: 592px;
  padding: 0;

  &__header {
    align-items: center;
    border-bottom: 1px solid #464e5f;
    display: flex;
    justify-content: space-between;
    padding: $padding;

    &-chips {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-left: 12px;
    }
  }

  &__body {
    flex: 1;
    overflow: auto;
    padding: 24px $padding;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }

  &__controls {
  }

  &__user {
    align-items: center;
    display: flex;
    gap: 8px;

    &-name {
      color: $grey;
    }
  }

  &__message {
    display: flex;
    gap: 24px;
    position: relative;

    & > *:last-child {
      flex: 1;
    }
  }

  &__message {
    &-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &-body {
      font-size: 14px;
      margin-top: 8px;
    }

    &-footer {
      margin-top: 16px;
    }
  }

  &__time {
    color: grey;
    font-size: 12px;
    letter-spacing: 0.4px;
  }

  @at-root {
    .v-dialog.attachment-media-dialog {
      height: 100% !important;
    }
  }

  &__prepend {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-img {
      align-items: center;
      @include size(36px);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      position: relative;

      &:before {
        background: $blue;
        content: "";
        height: 100%;
        opacity: 0.06;
        position: absolute;
        width: 100%;
      }
    }

    &-stripe {
      background: $blue;
      flex: 1;
      opacity: 0.06;
      width: 4px;
    }
  }

  &__attachment-list {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 28px;
  }

  &__attachment {
    &-message {
      font-size: 14px;
      line-height: 1.42;

      &--empty {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    &-date {
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      margin-top: 4px;
    }
  }
}
</style>
