var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "subListFont"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "bodyFont",
    domProps: {
      "innerHTML": _vm._s(_vm.subTitle)
    }
  })]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "mt-6 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "primary--text"
  }, [_vm._v("Try me 👇")])]), _c('v-col', {
    staticClass: "mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative",
      "padding-bottom": "calc(67.51054852320675% + 41px)",
      "height": "0"
    }
  }, [_c('iframe', {
    staticStyle: {
      "position": "absolute",
      "top": "0",
      "left": "0",
      "width": "100%",
      "height": "100%",
      "color-scheme": "light"
    },
    attrs: {
      "src": _vm.iFrameLink,
      "title": _vm.iFrameTitle,
      "allowfullscreen": "",
      "frameborder": "0",
      "loading": "lazy",
      "mozallowfullscreen": "",
      "webkitallowfullscreen": ""
    }
  })])])], 1)], 1), _c('v-divider'), _c('v-card-actions', [_vm.showCheckbox ? _c('v-checkbox', {
    attrs: {
      "label": "Don't show this again"
    },
    model: {
      value: _vm.openHelpModal,
      callback: function callback($$v) {
        _vm.openHelpModal = $$v;
      },
      expression: "openHelpModal"
    }
  }) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    staticClass: "my-5 text-transform-none",
    attrs: {
      "color": "primary",
      "large": "",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.openLink
    }
  }, [_vm._v("Documentation ")]), !_vm.showCheckbox ? _c('v-spacer') : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }