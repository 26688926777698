<template>
  <div>
    <v-row v-if="getReleaseDetailErrorMessage">
      <v-col cols="12">
        <no-data
          full-page-error
          :first-text="getReleaseDetailErrorMessage"
        ></no-data>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="smallFont darkGrey--text" cols="12">
        <span>{{ getAppInfo.name }} </span>/
        <span
          @click="
            $router.push({
              name: 'app-release-index',
              params: { app_id: $route.params.app_id },
            })
          "
          class="cursor-pointer info--text"
          >Releases</span
        >
        /
        <span class="info--text"> {{ releaseDetail?.id }}</span>
        <span
          v-if="$route.params.comment_id"
          class="info--text cursor-pointer"
          @click="$router.push({ name: 'release-comment-list' })"
        >
          / Comment /
        </span>
        <span v-if="$route.params.comment_id" class="info--text">{{
          $route.params.comment_id
        }}</span>
      </v-col>
      <v-col cols="12">
        <v-card :outlined="$vuetify.theme.dark" flat class="pa-2">
          <v-row v-if="getAppInfoLoading">
            <v-col>
              <v-skeleton-loader type="list"></v-skeleton-loader>
            </v-col>
            <v-col>
              <v-skeleton-loader type="button"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="auto">
              <v-row align="center">
                <v-col cols="auto">
                  <div v-if="releaseLoading">
                    <v-skeleton-loader type="button"></v-skeleton-loader>
                  </div>
                  <div v-else>
                    <img
                      :src="
                        releaseDetail?.image ||
                        `/media/defaults/${
                          $vuetify.theme.dark
                            ? 'app_default_light'
                            : 'app_default'
                        }.svg`
                      "
                      height="50"
                      @error="replaceDefaultReleaseImage"
                    />
                  </div>
                </v-col>
                <v-col>
                  <div v-if="releaseLoading">
                    <v-skeleton-loader type="button, list"></v-skeleton-loader>
                  </div>
                  <div v-else>
                    <div>
                      <v-icon
                        v-text="'mdi-android'"
                        color="success"
                        class="mt-n2"
                        size="30"
                        v-if="
                          releaseDetail?.platform?.toLowerCase() === 'android'
                        "
                      ></v-icon>
                      <v-icon
                        v-text="'mdi-apple'"
                        size="30"
                        class="mt-n2"
                        v-if="releaseDetail?.platform?.toLowerCase() === 'ios'"
                      ></v-icon>
                      <span class="titleFont ml-2"
                        >{{ releaseDetail?.version }} ({{
                          releaseDetail?.version_code || ""
                        }})</span
                      >
                    </div>
                    <div
                      class="smallFont info--text"
                      v-if="
                        [
                          getAppInfo?.latest_releases?.android,
                          getAppInfo?.latest_releases?.ios,
                        ].includes(releaseDetail.id)
                      "
                    >
                      Latest Release
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="text-right">
              <!--              <v-btn icon>-->
              <!--                <v-icon v-text="'mdi-bell-circle-outline'"></v-icon>-->
              <!--              </v-btn>-->
              <v-btn
                v-if="showArchive"
                :disabled="releaseLoading"
                @click="archiveModal = true"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="darkGrey">
                      mdi-archive-outline
                    </v-icon></template
                  >
                  <span>Archive Release</span>
                </v-tooltip>
              </v-btn>

              <v-tooltip
                v-if="canManageRelease && [2, 3].includes(releaseDetail.status)"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="releaseLoading"
                    @click="deleteModal = true"
                    icon
                  >
                    <v-icon color="error" v-text="'mdi-delete'"></v-icon>
                  </v-btn>
                </template>
                <span> Delete Release </span>
              </v-tooltip>
              <v-btn
                v-if="[1].includes(releaseDetail.status)"
                :disabled="releaseLoading"
                @click="onDownloadClick(releaseDetail)"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="darkGrey">
                      get_app
                    </v-icon></template
                  >
                  <span>Download App File</span>
                </v-tooltip>
              </v-btn>
              <!-- <v-btn
                v-if="releaseDetail?.status === 1"
                :disabled="releaseLoading"
                @click="openInstall"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="darkGrey">
                      mdi-qrcode
                    </v-icon></template
                  >
                  <span>Install Release</span>
                </v-tooltip>
              </v-btn> -->
              <template v-if="canManageRelease">
                <v-tooltip v-if="releaseDetail?.status !== 1" bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-btn disabled icon>
                        <v-icon>mdi-share-variant</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span> This release is not active </span>
                </v-tooltip>
                <v-tooltip
                  v-else-if="
                    releaseDetail?.platform === 'ios' &&
                    releaseDetail?.meta?.signingType !== 'enterprise'
                  "
                  bottom
                >
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-btn @click="iosShareLearnMore = true" icon>
                        <v-icon>mdi-share-variant</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span>
                    Public install links for iOS apps are exclusive to
                    Enterprise builds. <br />
                    Ad-hoc builds can be shared with unlimited and free guest
                    testers. Click to learn more...
                  </span>
                </v-tooltip>
                <v-menu v-else bottom nudge-bottom="35">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :disabled="releaseLoading" icon>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="darkGrey">
                            mdi-share-variant
                          </v-icon></template
                        >
                        <span>Share Release</span>
                      </v-tooltip>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="openInstall">
                      <v-icon left v-text="'mdi-qrcode'"></v-icon>
                      Share Internally
                    </v-list-item>
                    <v-list-item @click="generateShareLinkModal = true">
                      <v-icon left v-text="'mdi-share'"></v-icon>
                      Share Externally
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <v-btn
                :disabled="
                  !releaseDetail?.navigation?.previous?.id || releaseLoading
                "
                @click="getPreviousRelease"
                icon
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon
                      size="30"
                      color="darkGrey"
                      v-on="on"
                      v-text="'mdi-chevron-left-box-outline'"
                    ></v-icon>
                  </template>
                  <v-icon
                    left
                    color="darkGrey"
                    v-text="
                      releaseDetail?.navigation?.previous?.platform ===
                      'android'
                        ? 'mdi-android'
                        : 'mdi-apple'
                    "
                  >
                  </v-icon>
                  <span v-if="releaseDetail?.navigation?.previous?.version"
                    >{{
                      shortenVersion(
                        releaseDetail?.navigation?.previous?.version
                      )
                    }}
                    ({{
                      shortenVersion(
                        releaseDetail?.navigation?.previous?.version_code
                      )
                    }})</span
                  >
                </v-tooltip>
              </v-btn>
              <v-btn
                :disabled="
                  !releaseDetail?.navigation?.next?.id || releaseLoading
                "
                @click="getNextRelease"
                icon
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon
                      size="30"
                      v-on="on"
                      v-text="'mdi-chevron-right-box-outline'"
                    ></v-icon>
                  </template>
                  <v-icon
                    left
                    color="darkGrey"
                    v-text="
                      releaseDetail?.navigation?.next?.platform === 'android'
                        ? 'mdi-android'
                        : 'mdi-apple'
                    "
                  >
                  </v-icon>
                  <span v-if="releaseDetail?.navigation?.next?.version"
                    >{{
                      shortenVersion(releaseDetail?.navigation?.next?.version)
                    }}
                    ({{
                      shortenVersion(
                        releaseDetail?.navigation?.next?.version_code
                      )
                    }})</span
                  >
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <template v-if="releaseLoading">
        <v-col cols="12">
          <v-skeleton-loader type="heading"></v-skeleton-loader>
          <v-skeleton-loader type="divider"></v-skeleton-loader>
          <v-skeleton-loader
            type="card, list-item-two-line	"
          ></v-skeleton-loader>
        </v-col>
      </template>
      <template v-if="!releaseLoading">
        <v-col cols="12">
          <v-row class="mx-2 pb-2">
            <v-tabs v-model="selectedTab">
              <template v-for="(value, key) in releaseTabs">
                <tab
                  v-show="value.permissions"
                  :key="key"
                  :label="value.title"
                  :loading="releaseLoading"
                  @click="navigate(value)"
                />
              </template>
            </v-tabs>
          </v-row>
        </v-col>
        <template v-if="getReleaseDetailErrorMessage">
          <no-data :first-text="getReleaseDetailErrorMessage"></no-data>
        </template>
        <template v-else>
          <v-col v-if="selectedTab === 0" cols="12">
            <v-row v-if="releaseLoading">
              <v-col cols="12">
                <v-skeleton-loader type="card,list"></v-skeleton-loader>
              </v-col>
              <v-col cols="12">
                <v-skeleton-loader type="card,table"></v-skeleton-loader>
              </v-col>
            </v-row>
            <template v-else>
              <group-status-card
                outlined
                dot-type="icon"
                @update-notify="updateNotify"
                :notify-loading="notifyLoading"
                :stats="stats.filter((stat) => stat.permissions)"
              ></group-status-card>
              <v-card class="pa-4 mt-3" outlined>
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="pa-2 lightBackground">Information</div>
                    <div class="smallFont pa-2 pb-0">
                      <span>Release ID:</span>
                      <span class="ml-3">{{ releaseDetail?.id }}</span>
                    </div>
                    <div class="smallFont px-2">
                      <span
                        >{{
                          releaseDetail?.platform === "android"
                            ? "Package"
                            : "Bundle ID"
                        }}
                        :</span
                      >
                      <span class="ml-3">{{ releaseDetail?.package }}</span>
                    </div>
                    <div class="smallFont px-2">
                      <span>Signing:</span>
                      <span class="ml-3">{{
                        releaseDetail?.meta?.signingType || "-"
                      }}</span>
                    </div>

                    <div
                      v-if="releaseDetail?.platform === 'ios'"
                      class="smallFont px-2 pb-0"
                    >
                      <span>Platform Version:</span>
                      <span class="ml-3">{{
                        releaseDetail?.meta?.platformVersion
                      }}</span>
                    </div>
                    <div
                      v-if="releaseDetail?.platform === 'ios'"
                      class="smallFont px-2 pb-0"
                    >
                      <span>Minimum OS Version:</span>
                      <span class="ml-3">{{
                        releaseDetail?.meta?.minimumOSVersion
                      }}</span>
                    </div>
                    <div
                      v-if="releaseDetail?.platform === 'android'"
                      class="smallFont px-2 pb-0"
                    >
                      <span>Minimum SDK Version:</span>
                      <span class="ml-3">{{
                        releaseDetail?.meta?.minSDKVersion
                      }}</span>
                    </div>
                    <div
                      v-if="releaseDetail?.platform === 'android'"
                      class="smallFont px-2 pb-0"
                    >
                      <span>Target SDK Version:</span>
                      <span class="ml-3">{{
                        releaseDetail?.meta?.targetSDKVersion
                      }}</span>
                    </div>

                    <div
                      v-if="releaseDetail?.platform === 'ios'"
                      class="smallFont px-2 pb-0"
                    >
                      <span>Supported Platforms:</span>
                      <span class="ml-3">{{
                        releaseDetail?.meta?.supportedPlatforms?.join(", ") ||
                        "-"
                      }}</span>
                    </div>
                    <div
                      v-if="releaseDetail?.platform === 'ios'"
                      class="smallFont px-2 pb-0"
                    >
                      <span>Platforms:</span>
                      <span class="ml-3">{{
                        releaseDetail?.meta?.platforms?.join(", ") || "-"
                      }}</span>
                    </div>

                    <div class="smallFont px-2 mt-4">
                      <span>Developer:</span>
                      <span class="ml-3">{{ releaseDetail?.user?.name }}</span>
                    </div>
                    <div
                      v-if="releaseDetail?.archive_reason"
                      class="smallFont px-2"
                    >
                      <span>Archive reason:</span>
                      <span class="ml-3">{{
                        releaseDetail?.archive_reason
                      }}</span>
                    </div>

                    <div class="smallFont px-2 pb-0 mt-4">
                      <span>Source:</span>
                      <span class="ml-3">
                        {{ releaseSourceDetail.name || "-" }}
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              size="20"
                              v-text="'mdi-information-outline'"
                            ></v-icon>
                          </template>
                          <div>
                            <div>Uploaded from</div>
                            <ul>
                              <li>
                                Source:
                                {{ releaseSourceDetail.name || "-" }}
                              </li>
                              <li>
                                Version:
                                {{ releaseSourceDetail.version || "-" }}
                              </li>
                              <li>
                                Source version:
                                {{ releaseSourceDetail.source_versions || "-" }}
                              </li>
                              <li>
                                Git release notes:
                                <v-icon
                                  :color="
                                    releaseSourceDetail.git_release_notes
                                      ? 'success'
                                      : 'error'
                                  "
                                  v-text="
                                    releaseSourceDetail.git_release_notes
                                      ? 'mdi-check'
                                      : 'mdi-close'
                                  "
                                ></v-icon>
                              </li>
                            </ul>
                          </div>
                        </v-tooltip>
                      </span>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <div class="pa-2 lightBackground">
                      Release notes
                      <span
                        v-if="canManageRelease"
                        class="float-right cursor-pointer"
                      >
                        <v-icon
                          @click="onEditClick"
                          v-text="'mdi-pencil'"
                        ></v-icon>
                      </span>
                    </div>
                    <div class="smallFont pa-2 pb-0">
                      <div v-if="releaseDetail.note" class="text-pre-wrap">
                        <span v-if="readMoreNote">
                          {{ releaseDetail?.note.trim() }}
                        </span>
                        <span v-else>
                          {{ releaseDetail?.note?.trim()?.slice(0, 600) }}
                        </span>
                        <span
                          v-if="releaseDetail?.note?.trim()?.length > 600"
                          @click="readMoreNote = !readMoreNote"
                          class="primary--text cursor-pointer"
                        >
                          {{ readMoreNote ? "Read less..." : "Read more..." }}
                        </span>
                      </div>
                      <span v-else> - </span>
                    </div>
                  </v-col>

                  <v-col
                    v-if="releaseDetail?.platform === 'android'"
                    class="cursor-pointer"
                    @click="showPermissions = !showPermissions"
                    cols="6"
                  >
                    <div class="pa-2 lightBackground">
                      Permissions
                      <span
                        v-if="canManageRelease"
                        class="float-right cursor-pointer"
                      >
                        <v-icon
                          v-text="
                            !showPermissions
                              ? 'mdi-chevron-down'
                              : 'mdi-chevron-up'
                          "
                        ></v-icon>
                      </span>
                    </div>
                    <div v-if="showPermissions" class="mt-4">
                      <v-expand-transition
                        v-if="releaseDetail?.meta?.usesPermissions?.length"
                      >
                        <permission-mapper
                          from-release-page
                          :permissions="
                            releaseDetail?.meta?.usesPermissions || []
                          "
                        ></permission-mapper>
                      </v-expand-transition>
                      <div class="ml-2" v-else>No permissions found</div>
                    </div>
                  </v-col>
                  <v-col v-if="releaseDetail?.platform === 'ios'" cols="6">
                    <div class="pa-2 lightBackground">
                      <span
                        @click="showUDID = !showUDID"
                        class="cursor-pointer"
                      >
                        Provisioned Devices ({{
                          releaseDetail?.meta?.provisionedDevices?.length || "0"
                        }}
                        / 100)
                      </span>

                      <!-- <span class="float-right cursor-pointer mt-n1">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              v-if="
                                releaseDetail?.meta?.provisionedDevices?.length
                              "
                              :disabled="
                                !releaseDetail?.meta?.provisionedDevices?.length
                              "
                              @click="downloadProvisionalDevice"
                              icon
                            >
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </template>
                          <span>Download UDIDs</span>
                        </v-tooltip>

                        <v-btn icon small @click="openUDIDHelpModal">
                          <v-icon size="20">mdi-help-circle-outline</v-icon>
                        </v-btn>
                      </span> -->
                      <span
                        @click="showUDID = !showUDID"
                        class="float-right cursor-pointer"
                      >
                        <v-icon
                          v-text="
                            !showUDID ? 'mdi-chevron-down' : 'mdi-chevron-up'
                          "
                        ></v-icon>
                      </span>
                    </div>

                    <v-expand-transition>
                      <div v-if="showUDID" class="mt-4">
                        <div
                          v-if="releaseDetail?.meta?.provisionedDevices?.length"
                        >
                          <div
                            v-for="(udid, uindex) in releaseDetail?.meta
                              ?.provisionedDevices"
                            :key="uindex"
                            class="mb-1"
                          >
                            <span class="mr-2"> {{ uindex + 1 }}. </span>
                            <span>
                              {{ udid }}
                            </span>
                          </div>
                        </div>
                        <div class="ml-2" v-else>No devices found</div>
                      </div>
                    </v-expand-transition>
                  </v-col>
                  <v-col v-if="releaseDetail?.platform === 'ios'" cols="6">
                    <div class="pa-2 lightBackground">Certificate</div>
                    <div
                      v-if="releaseDetail?.platform === 'ios'"
                      class="smallFont px-2 pb-0"
                    >
                      <span>Created:</span>
                      <span class="ml-3">
                        <date-tooltip
                          :date="releaseDetail?.meta?.creationDate"
                        />
                      </span>
                    </div>
                    <div
                      v-if="releaseDetail?.platform === 'ios'"
                      class="smallFont px-2 pb-0"
                    >
                      <span>Expire:</span>
                      <span class="ml-3">
                        <date-tooltip
                          :date="releaseDetail?.meta?.expirationDate"
                        />
                      </span>
                    </div>
                    <div
                      v-if="releaseDetail?.platform === 'ios'"
                      class="smallFont px-2 pb-0"
                    >
                      <span>Team Identifier:</span>
                      <span class="ml-3">
                        {{
                          releaseDetail?.meta?.teamIdentifier?.join(", ") || "-"
                        }}
                      </span>
                    </div>
                    <div
                      v-if="releaseDetail?.platform === 'ios'"
                      class="smallFont px-2 pb-0"
                    >
                      <span>Provisioning profile name:</span>
                      <span class="ml-3">
                        {{ releaseDetail?.meta?.provisioningProfileName }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-col>
          <v-col v-if="selectedTab === 1" cols="12">
            <commentdetail
              :key="releaseDetail.id"
              :release="releaseDetail"
            ></commentdetail>
          </v-col>
          <v-col v-if="selectedTab === 2">
            <install-list
              :key="releaseDetail.id"
              from-release-page
              :release-id="releaseDetail?.id"
            ></install-list>
          </v-col>
        </template>
      </template>
    </v-row>
    <Modal closeable v-model="iosShareLearnMore">
      <template #card>
        <ios-share-info></ios-share-info>
      </template>
    </Modal>
    <Modal closeable hide-close v-model="installModal">
      <template #message>
        <v-row justify="center">
          <v-col cols="12">
            <h1>Internal Share</h1>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="font-16" cols="12">
            <p>
              <b>Share Securely Within Your Team</b><br /><br />
              This release is exclusive to your team members with app access.
              Manage your team’s access in the
              <span
                class="primary--text cursor-pointer"
                @click="
                  $router.push(`/app/${$route.params.app_id}/settings/releases`)
                "
                >App Settings</span
              >.
            </p>
            <br />
            <p>
              <b>Members ready to test:</b>
              {{ getAppInfo.stats?.members?.active || 1 }}
            </p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="font-16 font-weight-bold" cols="12">
            Instant Access with QR Code
          </v-col>
          <v-col cols="12">
            <qrcode
              :options="{
                errorCorrectionLevel: 'Q',
                width: 200,
              }"
              :value="installLink"
            ></qrcode>
          </v-col>
          <v-col class="text-center bodyFont" cols="12">
            Scan this QR code from your phone to open it in TestApp.io app
          </v-col>
          <v-col class="bodyFont" cols="12">
            <ul>
              <li>
                Install our
                <a
                  href="https://play.google.com/store/apps/details?id=testapp.io&hl=en_US&gl=US"
                  target="_blank"
                  >Android App</a
                >
              </li>
              <li>
                Install our
                <a
                  href="https://apps.apple.com/us/app/testapp-io/id1518972541"
                  target="_blank"
                  >iOS App</a
                >
              </li>
            </ul>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal
      v-model="generateShareLinkModal"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :width="$vuetify.breakpoint.mdAndUp ? '850' : ''"
      @close="generateShareLinkModal = false"
    >
      <template #card>
        <v-card>
          <v-card-title>
            <v-spacer />
            <v-btn @click="generateShareLinkModal = false" icon>
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-0">
            <share-link-stepper
              v-if="generateShareLinkModal"
              :key="generateShareLinkModal"
              :release-detail="releaseDetail"
              @close="generateShareLinkModal = false"
              @refresh="afterSuccess"
            ></share-link-stepper>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
    <Modal v-model="openShareLinkModal" @close="openShareLinkModal = false">
      <template #message>
        <share-link-modal
          from-release-page
          :share-id="shareId"
        ></share-link-modal>
      </template>
    </Modal>
    <Modal v-model="viewEditRelease" @close="viewEditRelease = false">
      <!--  -->
      <template #message>
        <UpdateReleasePanel
          v-if="viewEditRelease"
          :app_id="getAppInfo.id"
          @success="setReleaseNote"
          :release="releaseDetail"
        />
      </template>

      <!--  -->
    </Modal>
    <Modal v-model="deleteModal">
      <template v-if="deleteModal" #card>
        <v-card>
          <v-card-title class="font-weight-bold">Delete release </v-card-title>
          <v-divider />
          <v-card-text class="my-4 message">
            <p>Are you sure you want to delete this release.</p>
            <v-alert
              border="left"
              class="mt-3"
              color="info"
              icon="info"
              outlined
            >
              Archived releases are not available for installation and are not
              counted towards your storage quota. <br /><br />
              They are there for your future reference.
            </v-alert>
            <v-alert
              border="left"
              class="mt-3"
              color="error"
              icon="error"
              outlined
            >
              Deleted releases will be removed from: <br /><br />
              <ul>
                <li>Releases page</li>
                <li>Installs page</li>
                <li>Share page</li>
                <li>Total releases stats (app and team)</li>
              </ul>
            </v-alert>
          </v-card-text>
          <v-divider />
          <v-card-actions class="py-3">
            <div>No undo for this action</div>
            <v-spacer />
            <v-btn
              class="text-transform-none"
              color="primary"
              text
              :disabled="busy"
              @click="deleteModal = false"
              >No
            </v-btn>
            <v-btn
              :loading="busy"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              color="primary"
              @click="onConfirmDelete"
              >Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
    <Modal v-model="archiveModal" warning>
      <template #card>
        <template v-if="archiveModal">
          <v-card class="my-n3">
            <v-card-title>
              <div class="darkGrey--text bold-text">Archive release</div>
            </v-card-title>
            <v-divider />
            <v-card-text class="mt-4 message">
              Are you sure you want to archive
              {{
                selectedItem && !selectedItem.length
                  ? "this release?"
                  : "these selected releases?"
              }}

              <v-alert
                border="left"
                class="mt-3"
                color="info"
                icon="info"
                outlined
              >
                By archiving releases, we will be deleting the (.aab / .apk /
                .ipa) <br /><br />
                <div v-if="archiveReadmore">
                  Those releases will not be visible or available for
                  installation in our app and public install links.<br /><br />

                  Keeping all the data attached to these releases such as notes,
                  installs, feedback,... for your future reference.
                </div>
                <b
                  v-if="!archiveReadmore"
                  class="pointer"
                  @click="archiveReadmore = !archiveReadmore"
                >
                  {{ "Read more..." }}
                </b>
              </v-alert>
              <v-alert
                border="left"
                class="mt-3"
                color="success"
                icon="info"
                outlined
              >
                Archived releases will not be counted towards your team's
                storage quota 👍
              </v-alert>
            </v-card-text>
            <v-divider />
            <v-card-text class="pt-3">
              <v-row align="center">
                <v-col
                  :cols="!getTeamPlanUsage?.auto_archive_release ? 10 : 12"
                  class="py-2"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="noValue"
                        :disabled="!getTeamPlanUsage?.auto_archive_release"
                        :value="0"
                        hide-details
                        label="Now"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-row align="center">
                        <v-col>
                          <v-text-field
                            v-model="selectedDay"
                            :disabled="
                              noValue || !getTeamPlanUsage.auto_archive_release
                            "
                            filled
                            hide-details
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-select
                            v-model="selectedTime"
                            :disabled="
                              noValue || !getTeamPlanUsage.auto_archive_release
                            "
                            :items="['Days', 'Months']"
                            filled
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col
                          v-if="!getTeamPlanUsage.auto_archive_release"
                          cols="auto"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <v-btn icon @click="showStartTrialModal = true">
                                  <v-icon
                                    class="cursor-pointer"
                                    color="primary"
                                    v-text="'mdi-lock-outline'"
                                  >
                                  </v-icon>
                                </v-btn>
                              </span>
                            </template>
                            <span
                              >Auto archive settings is available for Starter &
                              Pro team plans</span
                            >
                          </v-tooltip>
                        </v-col>
                        <v-col class="py-0" cols="12">
                          Note: Maximum 90 days or 3 months
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <div>
                    You can change this setting for future releases from the
                    <span
                      class="primary--text cursor-pointer"
                      @click="
                        $router.push(
                          `/app/${$route.params.app_id}/settings/releases`
                        )
                      "
                      >app settings</span
                    >
                    page
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="py-3">
              <v-spacer />
              <v-btn
                class="mr-2 text-transform-none"
                color="primary"
                text
                :disabled="busy"
                @click="archiveModal = false"
                >No
              </v-btn>
              <v-btn
                :loading="busy"
                :outlined="$vuetify.theme.dark"
                class="text-transform-none"
                color="primary"
                @click="onConfirmArchive"
                >Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </template>
    </Modal>
    <Modal
      v-model="showHelpModal"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      width="700"
    >
      <!-- sub-title="UDIDs are essential for building and distributing iOS apps to specific devices for testing. <br /><br /> They are used in provisioning and distribution profiles for ad-hoc and TestFlight distribution. Without UDIDs, you cannot include devices in the profiles, and therefore cannot distribute the app for testing." -->
      <template #card>
        <demo-card
          v-if="showHelpModal"
          :event-type="eventName"
          :show-checkbox="showCheckbox"
          document-link="https://help.testapp.io/faq/how-to-add-udid-to-apple-developer-account/#how-to-register-udid-with-a-developer-account"
          event-name="Download UDIDs"
          i-frame-link="https://demo.arcade.software/w5ddiLruSIZFuJf1mtee?embed"
          sub-title="UDIDs are essential for distributing iOS apps to specific devices for testing."
          title="Downloading iOS UDIDs"
          @close="showHelpModal = false"
        ></demo-card>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  ARCHIVE_RELEASE_SCHEDULE,
  DELETE_RELEASE,
} from "@/store/releases/releases.module";
import { isEmpty } from "@/core/services/helper.service";
import GroupStatusCard from "@/view/components/Sessions/GroupStatusCard";
import { formatBytes, shortenVersion } from "@/core/services/helper.service";
import InstallList from "@/view/components/App/Dashboard/InstallList.vue";
import { releaseStatus } from "@/consts/statuses";
import Tab from "@/view/components/Common/Tab.vue";
import StorageService from "@/core/services/localstorage.service";
import Commentdetail from "@/view/components/Comment/Commentdetail";
import UpdateReleasePanel from "@/view/components/Releases/UpdateReleasePanel.vue";
import ShareLinkStepper from "@/view/components/Releases/ShareLinkStepper";
import ShareLinkModal from "@/view/components/Releases/ShareLinkModal";
import PermissionMapper from "@/view/components/Common/PermissionMapper";
import IosShareInfo from "./IosShareInfo.vue";
import DemoCard from "@/view/components/Common/DemoCard";
import { downloadUDIDs } from "@/mixins/downloadFile.js";

export default {
  components: {
    ShareLinkStepper,
    DemoCard,
    UpdateReleasePanel,
    Commentdetail,
    Tab,
    PermissionMapper,
    IosShareInfo,
    ShareLinkModal,
    GroupStatusCard,
    InstallList,
  },
  STATUS: releaseStatus,
  TABS: {
    "release-detail-page": 0,
    "release-comment": 1,
    "release-comment-list": 1,
    "release-install": 2,
  },
  data() {
    return {
      formatBytes,
      showHelpModal: false,
      readMoreNote: false,
      showPermissions: false,
      iosShareLearnMore: false,
      shortenVersion,
      releaseDetail: {},
      shareId: "",
      downloadUDIDs,
      showUDID: false,
      selectedDay: 0,
      selectedTime: "Days",
      deleteModal: false,
      busy: false,
      noValue: true,
      archiveModal: false,
      archiveReadmore: false,
      notifyLoading: false,
      viewEditRelease: false,
      releaseLoading: false,
      generateShareLinkModal: false,
      eventName: "",
      installModal: false,
      openShareLinkModal: false,
      selectedTab: this.toNumber(this.$options.TABS[this.$route.name]),
      installLink: "",
    };
  },
  computed: {
    ...mapGetters({
      getAppInfo: "getAppInfo",
      user: "currentUser",
      getAppInfoLoading: "getAppInfoLoading",
      getInfoErrorMessage: "getInfoErrorMessage",
      getTeamPlanUsage: "getTeamPlanUsage",
      getReleaseDetailErrorMessage: "getReleaseDetailErrorMessage",
      getDataFromSocket: "getDataFromSocket",
    }),
    releaseSourceDetail() {
      if (!isEmpty(this.releaseDetail?.source)) {
        const title = ["direct", "local"].includes(
          this.releaseDetail?.source?.source
        )
          ? "TA-CLI"
          : this.releaseDetail?.source?.source;

        return {
          name: title,
          ...this.releaseDetail?.source,
        };
      } else {
        return {
          icon: "mdi-gesture-tap-button",
          name: "Manual",
          version: "Portal version",
        };
      }
    },
    showArchive() {
      return this.canManageRelease && [1].includes(this.releaseDetail.status);
    },
    releaseTabs() {
      return [
        {
          title: "Info",
          component: "release-detail-page",
          permissions: true,
          value: 0,
        },
        {
          title: "Comments",
          component: "release-comment-list",
          permissions: true,
          value: 1,
        },
        {
          title: "Installs",
          permissions: this.canManageRelease,
          component: "release-install",
          value: 2,
        },
      ];
    },
    stats() {
      return [
        {
          title: "Size",
          icon: "mdi-database",
          permissions: true,
          color: "info",
          count: formatBytes(this.releaseDetail.size),
        },
        {
          title: "Install",
          color: "info",
          permissions: this.canManageRelease,
          icon: "mdi-cellphone-arrow-down-variant",
          count:
            (this.releaseDetail?.stats?.installs || 0) +
            (this.releaseDetail?.stats?.downloads || 0),
          tooltip: `<ul>
            <li>Installs: ${this.releaseDetail?.stats?.installs}</li>
            <li>Downloads: ${this.releaseDetail?.stats?.downloads}</li>
          </ul>`,
        },
        {
          title: "Comments",
          color: "info",
          permissions: this.canManageRelease,
          icon: "mdi-comment-alert",
          count: this.releaseDetail?.stats?.comments,
        },
        {
          title: "Auto Archive",
          color: "info",
          time: true,
          permissions: this.canManageRelease,
          icon: "mdi-cellphone-arrow-down-variant",
          count: this.releaseDetail.archive_at,
        },
        {
          title: "Notified",
          color: "info",
          permissions: true,
          type: "icon",
          icon: "mdi-bell",
          status: this.releaseDetail?.status,
          count: this.releaseDetail?.notify
            ? "mdi-check-circle-outline"
            : "mdi-close-circle-outline",
        },
        {
          title: "Date",
          color: "info",
          permissions: true,
          icon: "mdi-calendar",
          time: true,
          count: this.releaseDetail?.created_at,
        },
        {
          title: "Status",
          permissions: true,
          icon: "mdi-cube",
          color: "info",
          count: this.$options?.STATUS[this.releaseDetail?.status]?.title,
        },
        // {
        //   title: 'Notified',
        //   icon: 'mdi-bell',
        //   color: 'info',
        //   count: this.releaseDetail.notify ? 'Sent': 'Unsent'
        // }
      ];
    },
  },
  watch: {
    "$route.name"(val) {
      this.selectedTab = this.$options.TABS[val];
    },
    noValue(val) {
      if (!val) {
        this.selectedDay = 0;
        this.selectedTime = "Days";
      }
    },
    getDataFromSocket(val) {
      if (val) {
        if (val.app_id == this.$route.params.app_id) {
          this.releaseDetail.status = val.status;
          this.releaseDetail.archive_at = val.archive_at;
        }
      }
    },
  },
  created() {
    if (!this.getInfoErrorMessage) {
      this.getReleaseDetail();
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.canManageRelease && this.$route.name === "release-install") {
        this.selectedTab = 1;
        this.$router.push({ name: "release-detail-page" });
      } else {
        this.selectedTab = this.$options.TABS[this.$route.name];
      }
    });
  },
  methods: {
    ...mapMutations({
      setReleaseDetailErrorMessage: "setReleaseDetailErrorMessage",
      setReleaseDetail: "setReleaseDetail",
    }),
    downloadProvisionalDevice() {
      this.openDownloadHelpModal();
      this.downloadUDIDs(
        this.releaseDetail?.meta?.provisionedDevices,
        this.$route.params.release_id
      );
    },
    openUDIDHelpModal() {
      this.showHelpModal = true;
      this.showCheckbox = false;
      this.eventName = "Release overview";
    },
    openDownloadHelpModal() {
      const popup = StorageService.getItem("popup");
      if (!popup || !popup.help_udid) {
        this.showHelpModal = true;
        this.showCheckbox = true;
      } else if (popup && popup.help_udid === "false") {
        this.showHelpModal = true;
        this.showCheckbox = true;
      }
    },
    updateNotify() {
      if (this.notifyLoading) return;
      this.notifyLoading = true;
      this.$coreApi
        .post(
          `${this.apiRoutes.core.releases.notify(
            this.$route.params.app_id,
            this.$route.params.release_id
          )}`
        )
        .then(({ data }) => {
          this.notifyUserMessage({ message: data.message });
          this.releaseDetail.notify = true;
          this.notifyLoading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.notifyLoading = false;
        });
    },
    afterSuccess(response) {
      this.shareId = response.uid;
      this.openShareLinkModal = true;
    },
    getReleaseDetail(releaseID) {
      this.setReleaseDetail({});
      this.setReleaseDetailErrorMessage(null);
      if (this.releaseLoading) return;
      this.releaseLoading = true;
      this.$coreApi
        .get(
          `${this.apiRoutes.core.releases.detail(
            this.$route.params.app_id,
            releaseID || this.$route.params.release_id
          )}`
        )
        .then(({ data }) => {
          this.releaseDetail = data ?? {};
          this.setReleaseDetail(data ?? {});
        })
        .catch((err) => {
          this.setReleaseDetailErrorMessage(err.message);
          this.notifyErrorMessage(err.message);
        })
        .finally(() => {
          this.releaseLoading = false;
        });
    },
    onConfirmDelete() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(DELETE_RELEASE, {
          app_id: this.$route.params.app_id,
          release_id: this.releaseDetail.id,
        })
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          this.$router.push({ name: "app-release-index" });
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        });
    },
    onConfirmArchive() {
      if (this.busy) return;
      this.busy = true;
      let value = this.noValue ? 0 : this.selectedDay;
      if (this.selectedTime === "Months") {
        value = this.selectedDay * 30;
      }
      this.$store
        .dispatch(ARCHIVE_RELEASE_SCHEDULE, {
          id: this.getAppInfo.id,
          ids: [this.releaseDetail.id],
          // release_id: this.selectedRelease.id,
          action: "add_scheduled_archive",
          method: "time",
          value: parseInt(value),
        })
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          this.archiveModal = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    onEditClick(release) {
      this.viewEditRelease = true;
    },
    onDownloadClick(release) {
      if (release.platform == "ios") {
        window.open(
          release?.url?.replace(new RegExp("plist"), "ipa") +
            "?user_id=" +
            this.user?.id,
          "_self"
        );
      } else {
        window.open(release?.url + "?user_id=" + this.user?.id, "_self");
      }
    },
    setReleaseNote(note) {
      this.releaseDetail.note = note;
      this.viewEditRelease = false;
    },
    getNextRelease() {
      if (this.releaseDetail?.navigation?.next?.id) {
        this.getReleaseDetail(this.releaseDetail?.navigation?.next?.id);
        if (this.$route.name === "release-comment") {
          this.$router.push({
            name: "release-comment-list",
            params: {
              app_id: this.$route.params.app_id,
              release_id: this.releaseDetail?.navigation?.next?.id,
            },
          });
        } else {
          this.$router?.replace({
            params: {
              app_id: this.$route.params.app_id,
              release_id: this.releaseDetail?.navigation?.next?.id,
            },
          });
        }
      }
    },
    getPreviousRelease() {
      if (this.releaseDetail?.navigation?.previous?.id) {
        this.getReleaseDetail(this.releaseDetail?.navigation?.previous?.id);
        if (this.$route.name === "release-comment") {
          this.$router.push({
            name: "release-comment-list",
            params: {
              app_id: this.$route.params.app_id,
              release_id: this.releaseDetail?.navigation?.previous?.id,
            },
          });
        } else {
          this.$router?.replace({
            params: {
              app_id: this.$route.params.app_id,
              release_id: this.releaseDetail?.navigation?.previous?.id,
            },
          });
        }
      }
    },
    navigate(item) {
      this.selectedTab = item.value;
      this.$router.push({
        name: item.component,
        params: {
          app_id: this.$route.params.app_id,
          release_id: this.$route.params.release_id,
        },
      });
    },
    replaceDefaultReleaseImage(e) {
      e.target.src = "/media/defaults/app_default.svg";
    },
    openInstall() {
      this.selectedRelease = this.releaseDetail;
      this.installLink = `${location.origin}/mobile-app/check?team_id=${this.getTeamId}&app_id=${this.$route.params.app_id}&release_id=${this.$route.params.release_id}`;
      this.installModal = true;
    },
  },
};
</script>

<style scoped></style>
