var render = function render(){
  var _vm$activeComment, _vm$activeComment$use, _vm$activeComment2, _vm$activeComment2$us, _vm$activeComment3, _vm$activeComment3$st;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.activeComment ? _c('v-card', {
    staticClass: "feedback-chat",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "feedback-chat__header"
  }, [_c('div', {
    staticClass: "feedback-chat__title"
  }, [_c('span', [_vm._v("Comment #" + _vm._s(_vm.activeComment.id))])]), _c('div', {
    staticClass: "feedback-chat__header-chips"
  }, [_c('v-chip', [_c('v-icon', {
    attrs: {
      "color": "black",
      "left": ""
    }
  }, [_vm._v("mdi-account-circle-outline")]), _c('span', {
    staticClass: "font-medium"
  }, [_vm._v(_vm._s(_vm.activeComment.user.name))])], 1), (_vm$activeComment = _vm.activeComment) !== null && _vm$activeComment !== void 0 && (_vm$activeComment$use = _vm$activeComment.user) !== null && _vm$activeComment$use !== void 0 && _vm$activeComment$use.external && (_vm$activeComment2 = _vm.activeComment) !== null && _vm$activeComment2 !== void 0 && (_vm$activeComment2$us = _vm$activeComment2.user) !== null && _vm$activeComment2$us !== void 0 && _vm$activeComment2$us.email ? _c('v-chip', [_c('v-icon', {
    attrs: {
      "color": "black",
      "left": ""
    }
  }, [_vm._v("mdi-mail")]), _c('span', {
    staticClass: "font-medium"
  }, [_vm._v(_vm._s(_vm.activeComment.user.email))])], 1) : _vm._e(), _vm.activeComment.status === 1 ? _c('v-chip', {
    attrs: {
      "color": "#fff5dd",
      "text-color": "orange"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "orange",
      "left": ""
    }
  }, [_vm._v("mdi-clock-outline")]), _c('span', {
    staticClass: "font-medium"
  }, [_vm._v("Not resolved")])], 1) : _vm._e(), _vm.activeComment.status === 2 ? _c('v-chip', {
    attrs: {
      "color": "#e0ffe1",
      "text-color": "green"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "green",
      "left": ""
    }
  }, [_vm._v("mdi-check-circle-outline")]), _c('span', {
    staticClass: "font-medium"
  }, [_vm._v("Resolved")])], 1) : _vm._e()], 1), _c('v-spacer'), _c('div', {
    staticClass: "feedback-chat__controls"
  }, [_c('v-menu', {
    attrs: {
      "bottom": "",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-vertical")])], 1)];
      }
    }], null, false, 3221905750)
  }, [_c('v-list', {
    staticStyle: {
      "padding": "0"
    }
  }, [_c('v-list-item', {
    staticStyle: {
      "padding": "0 16px 0 12px"
    },
    attrs: {
      "ripple": ""
    },
    on: {
      "click": _vm.copyLink
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-content-copy")]), _c('v-list-item-title', {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("Copy link ")])], 1), _vm.canManageRelease ? _c('v-list-item', {
    staticStyle: {
      "padding": "0 16px 0 12px"
    },
    attrs: {
      "ripple": ""
    },
    on: {
      "click": function click($event) {
        return _vm.changeFeedbackStatus(_vm.activeComment.status);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")]), _c('v-list-item-title', {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v(" Mark as " + _vm._s(this.activeComment.status === 1 ? "Resolved" : "Not Resolved") + " ")])], 1) : _vm._e(), _c('v-list-item', {
    staticStyle: {
      "padding": "0 16px 0 12px"
    },
    attrs: {
      "ripple": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteFeedbackDialog = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-delete")]), _c('v-list-item-title', {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v(" Delete ")])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "feedback-chat__body"
  }, [_c('div', {
    staticClass: "feedback-chat__messages"
  }, [_c('div', {
    staticClass: "feedback-chat__message"
  }, [!_vm.attachmentsBusy ? [_c('div', {
    staticClass: "feedback-chat__prepend"
  }, [_c('div', {
    staticClass: "feedback-chat__prepend-img"
  }, [_c('v-icon', {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-message-reply-text-outline ")])], 1), _c('div', {
    staticClass: "feedback-chat__prepend-stripe"
  })]), _c('div', {
    staticClass: "feedback-chat__attachment"
  }, [_vm.activeComment.message ? _c('div', {
    staticClass: "feedback-chat__attachment-message text-pre-wrap",
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  }) : _c('div', {
    staticClass: "feedback-chat__attachment-message feedback-chat__attachment-message--empty"
  }, [_vm._v(" No message ")]), _c('div', {
    staticClass: "feedback-chat__attachment-date darkGrey--text"
  }, [_vm.activeComment.created_at ? _c('vue-hoverable-date', {
    attrs: {
      "date": _vm.activeComment.created_at
    }
  }) : _vm._e()], 1), _vm.attachments.length > 0 ? _c('div', {
    staticClass: "feedback-chat__attachment-list"
  }, _vm._l(_vm.attachments, function (attachment, index) {
    return _c('feedback-attachment', {
      key: index,
      attrs: {
        "from-comment-page": "",
        "attachment": attachment,
        "comment-id": _vm.activeComment.id
      },
      on: {
        "onDelete": _vm.handleDeleteAttachment,
        "onMediaClick": _vm.handleMediaClick
      }
    });
  }), 1) : _vm._e()])] : [_c('div', {
    staticClass: "feedback-chat__attachment-list"
  }, _vm._l((_vm$activeComment3 = _vm.activeComment) === null || _vm$activeComment3 === void 0 ? void 0 : (_vm$activeComment3$st = _vm$activeComment3.stats) === null || _vm$activeComment3$st === void 0 ? void 0 : _vm$activeComment3$st.attachments, function (index) {
    return _c('feedback-attachment-skeleton', {
      key: index
    });
  }), 1)]], 2)])]), _c('v-dialog', {
    attrs: {
      "content-class": "attachment-media-dialog",
      "height": "100%",
      "scrollable": "",
      "width": "auto"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.activeMedia && _vm.activeMedia.mime.includes('image') ? _c('img', {
    style: {
      aspectRatio: _vm.activeMedia.width / _vm.activeMedia.height
    },
    attrs: {
      "src": _vm.activeMedia.url,
      "height": "100%"
    }
  }) : _vm._e(), _vm.activeMedia && _vm.activeMedia.mime.includes('video') ? _c('video', {
    style: {
      aspectRatio: _vm.activeMedia.width / _vm.activeMedia.height
    },
    attrs: {
      "src": _vm.activeMedia.url,
      "controls": "",
      "height": "100%"
    }
  }) : _vm._e()]), _c('v-dialog', {
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.deleteFeedbackDialog,
      callback: function callback($$v) {
        _vm.deleteFeedbackDialog = $$v;
      },
      expression: "deleteFeedbackDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "font-size-h3"
  }, [_vm._v("Delete Comment")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "bodyFont p-2"
  }, [_vm._v(" Are you sure you want to delete this comment? ")]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteFeedbackDialog = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "loading": _vm.deleteFeedbackLoading,
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.handleDelete
    }
  }, [_vm._v("Delete ")])], 1)], 1)], 1)], 1) : _c('stack', [_c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-avatar"
    }
  }), _vm._l(2, function (index) {
    return _c('v-skeleton-loader', {
      key: index,
      attrs: {
        "type": "article"
      }
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }